import styled from "styled-components";

import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

import { mobileBreakPoint, tabletBreakPoint } from "../../constants/size";

const LoginStyledComponents = {
  StyledLogin: styled.div`
    width: 100%;
    height: 100%;

    ${getColor("background-color", "gray100")}

    > div {
      width: 100%;
      max-width: 480px;
      height: auto;
      padding: 72px 48px 77px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      border: 1px solid;
      ${getColor("border-color", "gray300")}
      border-radius: 6px;
      ${getColor("background-color", "white")}

     > svg {
        display: block;
        margin: 0 auto 70px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 24px 0;
        margin-bottom: 48px;
        padding: 0;

        list-style: none;

        li {
          display: flex;
          flex-direction: column;

          label {
            margin-bottom: 3px;
            ${typography.body02M}
            ${getColor("color", "gray600")}
          }

          > div {
            width: 100%;
          }
        }
      }

      button {
        width: 100%;
      }

      p {
        margin: 90px 0 0;
        ${typography.body02M}
        ${getColor("color", "black")}
        text-align: center;

        a {
          ${getColor("color", "blue500")}

          &:first-of-type {
            margin-left: 8px;
          }
        }
      }

     > span {
        display: block;
        ${typography.body02M}
        text-align: center;
        ${getColor("color", "gray600")}
      }
    }

    @media (max-width: ${tabletBreakPoint}px) {
      > div {
        position: static;
        transform: translate(0, 0);
        max-width: 100%;
        height: 100%;
        padding: 100px 20px;
        border: none;
      }
    }
  `,
};

export default LoginStyledComponents;
