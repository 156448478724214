"use client";

import { useRouter } from "next/navigation";
import { FormEvent, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase/app";
import { getRole } from "../../utils/network/role";
import { setAuth } from "../../utils/network/auth";

import { components } from "@r114dev/rds";

import LoginStyledComponents from "./styled";
const { StyledLogin } = LoginStyledComponents;

import Logo from "../../assets/svgs/LabsLogo.svg";

const {
  actions: { Button },
  textInput: { Input },
} = components;

const Login = () => {
  const { push } = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);

      const token = await user.getIdToken();

      await fetch("/auth", {
        method: "POST",
        body: JSON.stringify({ token }),
      });

      const role = await getRole(user.uid);

      await setAuth(user.uid, {
        token,
        role,
      });

      if (role === "master") {
        push("/admin/post");
      } else {
        push("/");
      }
    } catch {
      alert("입력한 정보가 올바르지 않습니다.");
    }
  };

  return (
    <StyledLogin>
      <div>
        <Logo />
        <form onSubmit={handleSubmit}>
          <ul>
            <li>
              <label htmlFor="email">이메일</label>
              <Input
                type="email"
                id="email"
                value={email}
                placeholder="이메일을 입력해주세요."
                onChange={(value) => setEmail(value)}
              />
            </li>
            <li>
              <label htmlFor="password">비밀번호</label>
              <Input
                type="password"
                id="password"
                value={password}
                placeholder="비밀번호를 입력해주세요."
                onChange={(value) => setPassword(value)}
              />
            </li>
          </ul>
          <Button type="submit" size="large" color="gray01">
            로그인
          </Button>
        </form>
        <p>
          관리자 문의:
          <a href="mailto:dnlwllms@hdc-labs.com" target="_blank">
            오동엽 매니저
          </a>
          ,{" "}
          <a href="mailto:masterforce999@hdc-labs.com" target="_blank">
            가동재 매니저
          </a>
        </p>
        <span>Copyright © HDC Labs Corp. All rights reserved.</span>
      </div>
    </StyledLogin>
  );
};

export default Login;
